import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";

var _this = this;

import store from '@/store';
import axios from 'axios';
import { Message } from 'element-ui';
import util from '@/libs/util';
import jsmd5 from "js-md5";
import router from '@/router';
import * as LocalStorage from 'lowdb/adapters/LocalStorage'; // import util from '@/libs/util.js'
// 创建一个错误

function errorCreate(msg) {
  var error = new Error(msg);
  errorLog(error);
  throw error;
} // 记录和显示错误


function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error: error
    }
  }); // 打印到控制台

  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>');
    console.log(error);
  } // 显示提示


  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
} // 创建一个 axios 实例


var service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 50000 // 请求超时时间

}); // 请求拦截器

service.interceptors.request.use(function (config) {
  // 在请求发送之前做一些处理
  // const token = util.cookies.get('token')
  // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  var appid = localStorage['admin_id'] != 'undefined' ? localStorage['admin_id'] : "9999";
  var key = localStorage['appkey'] ? localStorage['appkey'] : "32a1ff74699ff2d6ce4c497cb94cb5c8";
  var time = parseInt(new Date().valueOf() / 1000);
  var sign = jsmd5(appid + "" + time + key);
  var token = appid + "-" + time + "-" + sign;
  var businessId = localStorage['business_id'] ? localStorage['business_id'] : 0;
  var placeId = localStorage['place_id'] ? localStorage['place_id'] : 0;
  config.headers['x-token'] = localStorage["x_token"];
  config.headers['token'] = token;
  config.headers['businessId'] = businessId;
  config.headers['placeId'] = placeId;
  return config;
}, function (error) {
  // 发送失败
  console.log(error);
  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var that = _this;
  var dataAxios = response.data; // 这个状态码是和后端约定的

  var code = dataAxios.code; // 根据 code 进行判断

  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    if (code != 0) {
      //出错处理，如果要做登录处理等,都在这里进行
      if (code == 1) {
        Message({
          message: dataAxios.msg,
          type: 'success',
          duration: 10 * 1000
        });
      } else if (code == 9998) {
        Message({
          message: dataAxios.msg,
          type: 'error',
          duration: 10 * 1000
        });
        router.back(-1);
      } else if (code == 9001 || code == 9002 || code == 9003 || code == 9005 || code == 9007 || code == 9006) {
        Message({
          message: dataAxios.msg,
          type: 'error',
          duration: 10 * 1000
        }); // 清空 vuex 用户信息
        // await dispatch('d2admin/user/set', {}, { root: true })
        // 跳转路由
        // router.push({ name: 'login' })

        router.replace('/login');
      } else {
        Message({
          message: dataAxios.msg,
          type: 'error',
          duration: 10 * 1000
        });
      }
    }

    return dataAxios;
    /* switch (code) {
      case 0:
        // [ 示例 ] code === 0 代表没有错误
        return dataAxios.data
      case 'xxx':
        // [ 示例 ] 其它和后台约定的 code
        errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`)
        break
      default:
        // 不是正确的 code
        errorCreate(`${dataAxios.msg}: ${response.config.url}`)
        break
    } */
  }
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }
  }

  errorLog(error);
  return Promise.reject(error);
});
/**
 * 文件下载
 * @param config axios_config
 * @param filename 文件名
 */

service.download = function (config, filename) {
  config.responseType = "blob";
  service(config).then(function (res) {
    var blob = new Blob([res], {
      type: res.type
    });
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); //创建下载的链接

    downloadElement.href = href;
    downloadElement.download = filename; //下载后文件名

    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载

    document.body.removeChild(downloadElement); //下载完成移除元素

    window.URL.revokeObjectURL(href); //释放blob对象
  });
};
/**
 * 以新窗口的方式打开文件（例如：浏览pdf）
 * @param config
 */


service.open = function (config) {
  console.log(config);
  config.responseType = "blob";
  service(config).then(function (res) {
    var blob = new Blob([res], {
      type: res.type
    });
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); //创建下载的链接

    downloadElement.href = href;
    downloadElement.target = "_blank";
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载

    document.body.removeChild(downloadElement); //下载完成移除元素

    window.URL.revokeObjectURL(href); //释放blob对象
  });
};

export default service;