import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import _typeof from "D:/wwwroot/local81.lzj/bespeak/admin/node_modules/@babel/runtime/helpers/esm/typeof";
//http://localhost:8080/gasline/public/h5/#/
import constValue from '@/libs/constValues.js';
var picHost = 'shop.yabei.lzj';
var apiHost = process.env.VUE_APP_API;
var oauthUrl = 'http://ctyun.ycxxkj.com/gasline/public/index.php/api/oauth/oauth'; //鉴权网址,一般微信端H5才要

var appName = 'local';
var appKey = '32a1ff74699ff2d6ce4c497cb94cb5c8';
var currentEve = 1; //1本地模式，2测试环境,3生产环境
// var apiKey = '32a1ff74699ff2d6ce4c497cb94cb5c8'

var apiUrl = {
  doLogin: '/admin/index/doLogin',
  //登录
  menuList: '/admin/menu/getList',
  //主界面菜单
  changePwd: '/admin/business_user/changePwd',
  //修改密码
  adminDetail: '/admin/business_user/detail',
  //登录账号详情
  uploadImg: '/admin/upload/img',
  //上传图片
  placeList: '/admin/place/getList',
  //场馆列表
  placeDetail: '/admin/place/detail',
  //场馆详情
  placeEdit: '/admin/place/edit',
  //场馆编辑
  placeAdd: '/admin/place/add',
  getSelectPlace: '/admin/place/getSelectList',
  getIndexSelectList: '/admin/place/getIndexSelectList',
  getPlaceAttach: '/admin/place/getPlaceAttach',
  placeConfig: '/admin/place/config',
  getPlaceFields: '/admin/place/getFields',
  doDelPlace: '/admin/place/doDel',
  ticketList: '/admin/ticket_type/getList',
  //票种
  ticketDel: '/admin/ticket_type/del',
  //删除
  ticketEdit: '/admin/ticket_type/edit',
  //票种编辑
  ticketAdd: '/admin/ticket_type/add',
  //票种添加
  ticketDetail: '/admin/ticket_type/detail',
  //票种详情
  ticketChangeStatus: '/admin/ticket_type/changeStatus',
  //修改票种状态
  getTicketTypes: '/admin/ticket_type/getTicketTypes',
  packageTicketList: '/admin/package_ticket/packageTicketList',
  //票种
  doDelPackageTicket: '/admin/package_ticket/delPackageTicket',
  //删除
  editPackageTicket: '/admin/package_ticket/editPackageTicket',
  //票种编辑
  addPackageTicket: '/admin/package_ticket/addPackageTicket',
  //票种添加
  packageTicketDetail: '/admin/package_ticket/packageTicketDetail',
  //票种详情
  userPackageTicketList: '/admin/package_ticket/userPackageTicketList',
  userPackageTicket: '/admin/package_ticket/userPackageTicket',
  reserveList: '/admin/reserve/getList',
  //预订列表
  reserveDetail: '/admin/reserve/getDetail',
  //预订列表
  reserveSessionAdd: '/admin/reserve/sessionAdd',
  //添加场次
  reserveEdit: '/admin/reserve/edit',
  //预订列表修改
  reserveDel: '/admin/reserve/del',
  //预订列表删除
  reserveDelRow: '/admin/reserve/delRow',
  //批量删除
  reserveDetailAll: '/admin/reserve/detailAll',
  reserveDetailNew: '/admin/reserve/getDetailNew',
  //预订列表
  reserveEditNew: '/admin/reserve/editNew',
  //预订列表修改
  reserveSessionAddNew: '/admin/reserve/sessionAddNew',
  //添加场次
  ordersList: '/admin/orders/getList',
  //订单管理
  orderDetail: '/admin/orders/detail',
  //详情
  orderDetailEdit: '/admin/orders/orderDetailEdit',
  //修改详情的核销状态
  orderCancel: '/admin/orders/orderCancel',
  //单订单退款
  orderExport: '/admin/orders/exportDetail',
  getAllUser: '/admin/orders/getAllUser',
  getSwimSessionByDay: '/admin/orders/getSwimSessionByDay',
  getSessionTicketList: '/admin/orders/getSessionTicketList',
  doAddSwimOrder: '/admin/orders/doAddSwimOrder',
  doReplenishOrder: '/admin/orders/doReplenishOrder',
  //场地订单
  fieldOrderList: '/admin/field_order/fieldOrderList',
  getAllSessionByDay: '/admin/field_order/getAllSessionByDay',
  getAllSessionList: '/admin/field_order/getAllSessionList',
  doAddFieldOrder: '/admin/field_order/add',
  fieldOrderDetail: '/admin/field_order/detail',
  doEditFieldOrder: '/admin/field_order/edit',
  doCancelFieldOrder: '/admin/field_order/cancel',
  doRefundFieldOrder: '/admin/field_order/applyRefund',
  exportFieldOrder: '/admin/field_order/export',
  writeOffFieldOrder: '/admin/field_order/writeOff',
  fieldWriteOffList: '/admin/field_order/writeOffList',
  fieldWriteOffExport: '/admin/field_order/exportWriteOff',
  getFieldAccounts: '/admin/field_order/getAccounts',
  fieldAccountsExport: '/admin/field_order/exportAccounts',
  getSessionListLastMonth: '/admin/field_order/getSessionListLastMonth',
  //长租订单
  getAllFieldList: '/admin/field_order/getAllFieldList',
  doAddLongFieldOrder: '/admin/field_order/doAddLongFieldOrder',
  getLongTimeOrder: '/admin/field_order/getLongTimeOrder',
  cancelLongTimeDetail: '/admin/field_order/cancelDetail',
  doCancelLongTimeOrder: '/admin/field_order/cancelLongTimeOrder',
  doChangeField: '/admin/field_order/changeDetail',
  //退款订单
  refundOrderList: '/admin/refund_field_order/list',
  doCancelFieldRefundOrder: '/admin/refund_field_order/cancel',
  doPassFieldRefundOrder: '/admin/refund_field_order/pass',
  //储物柜订单
  lockerOrderList: '/admin/locker_order/getList',
  doAddLockerOrder: '/admin/locker_order/add',
  lockerOrderDetail: '/admin/locker_order/detail',
  doRefundLockerOrder: '/admin/locker_order/refund',
  exportLockerOrder: '/admin/locker_order/export',
  //套票订单
  packageTicketOrderList: '/admin/package_ticket_order/packageTicketOrderList',
  addPackageTicketOrder: '/admin/package_ticket_order/addPackageTicketOrder',
  packageTicketOrderDetail: '/admin/package_ticket_order/packageTicketOrderDetail',
  doCancelPackageTicketOrder: '/admin/package_ticket_order/doCancelPackageTicketOrder',
  getAllPackageTicket: '/admin/package_ticket_order/getAllPackageTicket',
  editPackageTicketOrder: '/admin/package_ticket_order/editPackageTicketOrder',
  doRefundPackageTicketOrder: '/admin/package_ticket_order/doRefundPackageTicketOrder',
  exportPackageTicketOrder: '/admin/package_ticket_order/exportExcel',
  batchOrders: '/admin/orders/batchCancelOrders',
  batchWrite: '/admin/orders/batchWrite',
  batchCancelWrite: '/admin/orders/batchCancelWrite',
  writeOffGetSession: '/admin/write_off/getSession',
  writeOffGetDetail: '/admin/write_off/getDetail',
  writeOffCheck: '/admin/write_off/check',
  writeOffList: '/admin/write_off/getList',
  writeOffExport: '/admin/write_off/exportDetail',
  countList: '/admin/count/getList',
  //首页
  //角色管理
  roleList: '/admin/business_role/roleList',
  roleDetail: '/admin/business_role/roleDetail',
  roleEdit: '/admin/business_role/roleEdit',
  roleAdd: '/admin/business_role/roleAdd',
  roleDel: '/admin/business_role/roleDel',
  roleTreesDetail: '/admin/business_role/roleTree',
  roleSelect: '/admin/business_user/roleSelect',
  userList: '/admin/business_user/userList',
  userDetail: '/admin/business_user/userDetail',
  userEdit: '/admin/business_user/userEdit',
  userAdd: '/admin/business_user/userAdd',
  userDel: '/admin/business_user/userDel',
  resetPwd: '/admin/business_user/resetPwd',
  logList: '/admin/log/getList',
  //日志
  tdCode: '/admin/config/tdCode',
  //二维码
  tdCodeEdit: '/admin/config/tdCodeEdit',
  //二维码
  accountsPayList: '/admin/accounts/payList',
  //对账收入列表
  accountsRefuseList: '/admin/accounts/refuseList',
  //对账支出列表
  accountsExport: '/admin/accounts/exportAccounts',
  //导出对账
  statisticsTable: '/admin/statistics/count',
  fieldStatisticsTable: '/admin/statistics/statistics',
  exportFieldStatisticsTable: '/admin/statistics/exportStatistics',
  packageTicketStatisticsTable: '/admin/statistics/packageTicketStatistics',
  exportTicketStatisticsTable: '/admin/statistics/exportPackageTicketStatistics',
  lockerStatisticsTable: '/admin/statistics/lockerStatistics',
  exportLockerStatisticsTable: '/admin/statistics/exportLockerStatistics',
  longTimeStatisticsTable: '/admin/statistics/longTimeStatistics',
  exportLongTimeStatisticsTable: '/admin/statistics/exportLongTimeStatistics',
  dailyTable: '/admin/daily_sheet/dailySheet',
  fieldDailyTable: '/admin/daily_sheet/fieldDaily',
  getNotify: '/admin/notify/index',
  addNotify: '/admin/notify/doAdd',
  getNotifyDetail: '/admin/notify/detail',
  editNotify: '/admin/notify/doEdit',
  doDelNotify: '/admin/notify/doDel',
  getAgreement: '/admin/agreement/index',
  doAddAgreement: '/admin/agreement/add',
  doEditAgreement: '/admin/agreement/edit',
  agreementDetail: '/admin/agreement/detail',
  doDelAgreement: '/admin/agreement/del',
  getSwiperList: '/admin/swiper/getList',
  addSwiper: '/admin/swiper/doAdd',
  getSwiperDetail: '/admin/swiper/detail',
  editSwiper: '/admin/swiper/doEdit',
  doDelSwiper: '/admin/swiper/doDel',
  getFieldList: '/admin/field/getList',
  addField: '/admin/field/doAdd',
  getFieldDetail: '/admin/field/detail',
  editField: '/admin/field/doEdit',
  doDelField: '/admin/field/doDel',
  getSelectField: '/admin/field/getSelectField',
  getFieldAttach: '/admin/field/getFieldAttach',
  fieldConfig: '/admin/field/config',
  getSessionFieldList: '/admin/field/getSessionFieldList',
  sessionList: '/admin/session/index',
  getSessionDetailByDay: '/admin/session/detail',
  doSetSingleSession: '/admin/session/doSetSingleSession',
  doSetAllSession: '/admin/session/doSetAllSession',
  doDelSingleSession: '/admin/session/delSingleSession',
  doDelAllSession: '/admin/session/delAllSession',
  getReplenishTicketList: '/admin/session/getReplenishTicketList',
  getAreaList: '/admin/area/getList',
  getSportTypeList: '/admin/sport/getList',
  getSelectSport: '/admin/sport/getSelectSport'
};
var uploadServer = {
  host: '',
  uploadUrl: '/api/file/upload'
};
var apiCode = {
  unLogin: 1200,
  succ: -1
};

if (currentEve == 1) {
  //如果是开发模式  
  appName = 'dev';
}

if (currentEve == 2) {
  picHost = ''; // apiHost = "";

  appName = 'test';
}

var now = Date.now || function () {
  return new Date().getTime();
};

var isArray = Array.isArray || function (obj) {
  return obj instanceof Array;
};

function checkMobile(sMobile) {
  if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(sMobile)) {
    return false;
  }

  return true;
}

function isEmpty(obj) {
  //console.log("empty:obj:", obj);
  if (typeof obj == 'undefined' || obj == null || obj == '') {
    //console.log("empty:obj:return ", true);
    return true;
  } else {
    //console.log("empty:obj:return ", false);
    return false;
  }
}

function getRandomCode(length) {
  if (length > 0) {
    var data = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var nums = '';

    for (var i = 0; i < length; i++) {
      var r = parseInt(Math.random() * 61);
      nums += data[r];
    }

    return nums;
  } else {
    return false;
  }
}
/**
 * 获取当前时间
 */


function getNow() {
  var fmt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'time';
  var day2 = new Date();
  day2.setTime(day2.getTime());
  var timeStr = day2.getFullYear() + '-' + (day2.getMonth() + 1) + '-' + day2.getDate() + ' ' + day2.getHours() + ':' + day2.getMinutes() + ':' + day2.getSeconds();

  if (fmt == 'day') {
    timeStr = day2.getFullYear() + '-' + (day2.getMonth() + 1) + '-' + day2.getDate();
  }

  return timeStr;
}
/**
 * 获取格式化日期字符串
 */


function formatDayStr() {
  var preDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var day2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  // let day2 = new Date()
  console.log("preDays:", preDays);

  if (preDays != 0) {
    day2.setDate(day2.getDate() + preDays);
  }

  console.log("day2:", day2);
  var timeStr = day2.getFullYear() + '-' + (day2.getMonth() <= 9 ? '0' : '') + (day2.getMonth() + 1) + '-' + (day2.getDate() <= 9 ? '0' : '') + day2.getDate() + '';
  console.log("day2_str:", timeStr);
  return timeStr;
} // 是否是微信浏览器


function isWeixin() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false;
} //组合网址


function cmbUrl(path) {
  return apiHost + '/' + path;
}

function cmbUrlObj(obj, key) {
  var url = apiHost + '/'; // console.log("obj",obj);
  // console.log("key:",key);

  if (obj) {
    // console.log("key in obj",(key in obj));
    if (key in obj) {
      // console.log("obj.key",obj[key]);
      url += obj[key]; // console.log("url:",url);
    }
  }

  return url;
}
/**
 * 获取对象属性值
 * @param {Object} obj
 */


function getObjProperty(obj, property) {
  var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;

  //console.log("getObjProperty", obj);
  //console.log("property", property);
  if (property in obj) {
    return obj[property];
  }

  return defaultValue;
}

function uniShowError(info) {
  uni.showModal({
    content: info,
    showCancel: false,
    success: function success(res) {}
  });
}
/**
 * 设置本地存储
 */


function setLocalStorage(key, value) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "ycerp_";
  value = JSON.stringify(value); // console.log(prefix+key+" set value:",value);

  localStorage.setItem(prefix + key, value);
}
/**
 * 获取本地存储
 */


function getLocalStorage(key) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ycerp_";
  var value = localStorage.getItem(prefix + key);
  value = JSON.parse(value);
  return value;
}
/**
 * 移除本地缓存
 */


function removeLocalStorage(key) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ycerp_";
  localStorage.removeItem(prefix + key);
}
/**
 * 获取日期数组
 * @param count 天数
 * @returns {string[]}
 */


function timeForMat(count) {
  // 当天
  var time1 = new Date();
  time1.setTime(time1.getTime());
  var Y1 = time1.getFullYear();
  var M1 = time1.getMonth() + 1 > 10 ? time1.getMonth() + 1 : '0' + (time1.getMonth() + 1);
  var D1 = time1.getDate() > 10 ? time1.getDate() : '0' + time1.getDate();
  var timer1 = Y1 + '-' + M1 + '-' + D1; // 当前时间

  var time2 = new Date();
  time2.setTime(time2.getTime() - 24 * 60 * 60 * 1000 * count);
  var Y2 = time2.getFullYear();
  var M2 = time2.getMonth() + 1 > 10 ? time2.getMonth() + 1 : '0' + (time2.getMonth() + 1);
  var D2 = time2.getDate() > 10 ? time2.getDate() : '0' + time2.getDate();
  var timer2 = Y2 + '-' + M2 + '-' + D2; // 之前的7天或者30天

  return [timer2, timer1];
}

export default {
  picHost: picHost,
  apiHost: apiHost,
  apiUrl: apiUrl,
  appName: appName,
  appKey: appKey,
  oauthUrl: oauthUrl,
  now: now,
  getNow: getNow,
  isArray: isArray,
  checkMobile: checkMobile,
  isEmpty: isEmpty,
  getRandomCode: getRandomCode,
  isWeixin: isWeixin,
  cmbUrl: cmbUrl,
  apiCode: apiCode,
  getObjProperty: getObjProperty,
  uniShowError: uniShowError,
  cmbUrlObj: cmbUrlObj,
  constValue: constValue,
  getUchartsDateArr: function getUchartsDateArr() {
    var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -30;

    if (days > 0) {
      return [formatDayStr(0), formatDayStr(days)];
    }

    return [formatDayStr(days), formatDayStr(0)];
  },
  formatDayStr: formatDayStr,
  uploadServer: uploadServer,
  isJSON: function isJSON(str) {
    if (typeof str == 'string') {
      try {
        var obj = JSON.parse(str);

        if (_typeof(obj) == 'object' && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log('error：' + str + '!!!' + e);
        return false;
      }
    }

    console.log('It is not a string!');
    return true;
  },
  setLocalStorage: setLocalStorage,
  getLocalStorage: getLocalStorage,
  removeLocalStorage: removeLocalStorage,
  timeForMat: timeForMat
};