import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import helper from '@/libs/helper.js';
import axios from '@/plugin/axios';
import util from '@/libs/util.js';
export default {
  data: function data() {
    return {
      next: [],
      today: [],
      date: '',
      week: [],
      reserve: [],
      write: [],
      powerCode: localStorage['apcode'],
      isRoot: localStorage['is_root'],
      place: {}
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      console.log('place', localStorage.getItem('place_id'));
      var that = this;
      var url = helper.apiHost + helper.apiUrl.countList;
      var data = {};
      axios({
        url: url,
        method: 'post',
        data: data
      }).then(function (res) {
        console.log('then res', res);

        if (res.code == 0) {
          that.week = res.data.week;
          that.next = res.data.next;
          that.today = res.data.today;
          that.date = res.data.date;
          that.reserve = res.data.reserve;
          that.write = res.data.write;
          that.place = res.data.place;
        } else {
          // that.$message.error(res.msg);
          that.dialogFormVisible = false;
        }
      }).catch(function (error) {
        console.log('catch res', error);
        that.$message.error('服务器繁忙');
        that.dialogFormVisible = false;
      });
    },
    toReserve: function toReserve() {
      if (this.place.sport_type_ids > 1) {
        //场地
        this.$router.replace('/field_order');
      } else {
        //游泳
        this.$router.replace('/orders');
      }
    },
    toWrite: function toWrite() {
      if (this.place.sport_type_ids > 1) {
        //场地
        this.$router.replace('/field_order');
      } else {
        //游泳
        this.$router.replace('/writeOff_list');
      }
    }
  }
};