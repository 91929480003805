// // import { find, assign } from 'lodash';
import axios from '@/plugin/axios';
import helper from '@/libs/helper.js'; //
//
//
// export default ({ service,  serviceForMock, requestForMock, mock, faker, tools }) => ({
//   /**
//    * @description 登录
//    * @param {Object} data 登录携带的信息
//    */
//   SYS_USER_LOGIN (data = {}) {
//     // 模拟数据
//     mock
//       .onAny('/login')
//       .reply(config => {
//         const user = find(users, tools.parse(config.data));
//         return user
//           ? tools.responseSuccess(assign({}, user, { token: faker.random.uuid() }))
//           : tools.responseError({}, '账号或密码不正确')
//       })
//     // 接口请求
//     // return request({
//     //   url: '/admin/index/doLogin',
//     //   method: 'post',
//     //   data
//     // })
//
//     let  url = helper.apiHost + helper.apiUrl.doLogin;
//
//     return request({
//       url: url,
//       method: 'post',
//       data: data
//     })
//   },
//
//   AccountLogin (data) {
//     return request({
//       url: '/admin/index/doLogin',
//       method: 'post',
//       data:data
//     })
//   }
//
//
// })
//

import { find, assign } from 'lodash';
var users = [{
  username: 'admin',
  password: 'admin',
  uuid: 'admin-uuid',
  name: 'Admin'
}, {
  username: 'editor',
  password: 'editor',
  uuid: 'editor-uuid',
  name: 'Editor'
}, {
  username: 'user1',
  password: 'user1',
  uuid: 'user1-uuid',
  name: 'User1'
}];
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    SYS_USER_LOGIN: function SYS_USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 模拟数据
      mock.onAny('/login').reply(function (config) {
        var user = find(users, tools.parse(config.data));
        return user ? tools.responseSuccess(assign({}, user, {
          token: faker.random.uuid()
        })) : tools.responseError({}, '账号或密码不正确');
      }); // 接口请求

      var url = helper.apiHost + helper.apiUrl.doLogin;
      return axios({
        url: url,
        method: 'post',
        data: data
      });
      /*   return requestForMock({
           url: '/login',
           method: 'post',
           data
         })*/
    }
  };
});