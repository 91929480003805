import "D:\\wwwroot\\local81.lzj\\bespeak\\admin\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\wwwroot\\local81.lzj\\bespeak\\admin\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\wwwroot\\local81.lzj\\bespeak\\admin\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\wwwroot\\local81.lzj\\bespeak\\admin\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 菜单和路由设置

import router from './router';
import { menuHeader, menuAside } from '@/menu';
import { frameInRoutes } from '@/router/routes'; //vue-axios 配置

import VueAxios from 'vue-axios';
import axios from '@/plugin/axios/index.js';
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
  key: 'f630d87e239a56e431c350dcb5ba5349',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.Map', 'AMap.MouseTool', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Pixel', 'AMap.Geolocation', 'AMap.Marker'],
  uiVersion: '1.0' // 版本号

});
Vue.use(VueAxios, axios); // Vue.prototype.powerCode =  '';  //设置全局权限变量;
// 核心插件

Vue.use(d2Admin);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('d2admin/menu/headerSet', menuHeader); // 设置侧边栏菜单

    this.$store.commit('d2admin/menu/asideSet', menuAside); // 初始化菜单搜索功能

    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');