import _objectSpread from "D:/wwwroot/local81.lzj/bespeak/admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import helper from '@/libs/helper.js';
import axios from '@/plugin/axios';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },

    /**
     * 修改密码
     */
    changePwd: function changePwd() {
      this.$router.push({
        path: '/admin_pwd'
      });
    }
  })
};