import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  {
    path: '/index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, //修改密码
  {
    path: '/admin_pwd',
    name: 'admin_pwd',
    component: function component() {
      return import('@/pages/admin/pwd');
    },
    meta: {
      auth: true,
      title: '修改密码'
    }
  }, //场馆
  {
    path: '/place',
    name: 'place',
    component: function component() {
      return import('@/pages/place/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场馆列表'
    }
  }, {
    path: '/place_edit',
    name: 'place_edit',
    component: function component() {
      return import('@/pages/place/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场馆编辑'
    }
  }, {
    path: '/place/add',
    name: 'place_add',
    component: function component() {
      return import('@/pages/place/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场馆添加'
    }
  }, {
    path: '/place/config',
    name: 'place_config',
    component: function component() {
      return import('@/pages/place/config');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场馆附加项配置'
    }
  }, //地图
  {
    path: '/place/map',
    name: 'map',
    meta: {
      title: '地图',
      auth: false,
      cache: true
    },
    component: function component() {
      return import('@/pages/place/map');
    }
  }, //场地
  {
    path: '/field',
    name: 'field',
    component: function component() {
      return import('@/pages/field/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场地列表'
    }
  }, {
    path: '/field/add',
    name: 'field_add',
    component: function component() {
      return import('@/pages/field/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加场地'
    }
  }, {
    path: '/field/edit',
    name: 'field_edit',
    component: function component() {
      return import('@/pages/field/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑场地'
    }
  }, {
    path: '/field/config',
    name: 'field_config',
    component: function component() {
      return import('@/pages/field/config');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场地附加项配置'
    }
  }, //场次
  {
    path: '/session',
    name: 'session',
    component: function component() {
      return import('@/pages/session/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场次管理'
    }
  }, {
    path: '/session/add',
    name: 'session_add',
    component: function component() {
      return import('@/pages/session/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加场次'
    }
  }, {
    path: '/session/edit',
    name: 'session_edit',
    component: function component() {
      return import('@/pages/session/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑场次'
    }
  }, //票种管理
  {
    path: '/ticket',
    name: 'ticket',
    component: function component() {
      return import('@/pages/ticket/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '票种首页'
    }
  }, {
    path: '/ticket_form',
    name: 'ticket_form',
    component: function component() {
      return import('@/pages/ticket/form');
    },
    meta: {
      auth: true,
      cache: true,
      title: '票种管理'
    }
  }, //套票管理
  {
    path: '/package_ticket',
    name: 'package_ticket',
    component: function component() {
      return import('@/pages/package_ticket/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '套票首页'
    }
  }, {
    path: '/package_ticket/add',
    name: 'package_ticket_add',
    component: function component() {
      return import('@/pages/package_ticket/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加套票'
    }
  }, {
    path: '/package_ticket/edit',
    name: 'package_ticket_edit',
    component: function component() {
      return import('@/pages/package_ticket/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑套票'
    }
  }, //预订管理
  {
    path: '/reserve',
    name: 'reserve',
    component: function component() {
      return import('@/pages/reserve/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '预定管理'
    }
  }, //游泳订单
  {
    path: '/orders',
    name: 'orders',
    component: function component() {
      return import('@/pages/orders/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '票种订单管理'
    }
  }, {
    path: '/orders/add',
    name: 'orders_add',
    component: function component() {
      return import('@/pages/orders/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加票种订单'
    }
  }, //场地订单
  {
    path: '/field_order',
    name: 'field_order',
    component: function component() {
      return import('@/pages/field_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '场地订单管理'
    }
  }, {
    path: '/field_order/add',
    name: 'field_order_add',
    component: function component() {
      return import('@/pages/field_order/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加场地订单'
    }
  }, {
    path: '/field_order/edit',
    name: 'field_order_edit',
    component: function component() {
      return import('@/pages/field_order/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑场地订单'
    }
  }, //套票订单
  {
    path: '/package_ticket_order',
    name: 'package_ticket_order',
    component: function component() {
      return import('@/pages/package_ticket_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '套票订单管理'
    }
  }, {
    path: '/package_ticket_order/add',
    name: 'package_ticket_order_add',
    component: function component() {
      return import('@/pages/package_ticket_order/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加套票订单'
    }
  }, {
    path: '/package_ticket_order/edit',
    name: 'package_ticket_order_edit',
    component: function component() {
      return import('@/pages/package_ticket_order/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑套票订单'
    }
  }, //场地退订申请
  {
    path: '/refund_field_order',
    name: 'refund_field_order',
    component: function component() {
      return import('@/pages/refund_field_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '退款订单管理'
    }
  }, //场地长租订单
  {
    path: '/long_time_order',
    name: 'long_time_order',
    component: function component() {
      return import('@/pages/long_time_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '长租订单管理'
    }
  }, {
    path: '/long_time_order/add',
    name: 'long_time_order_add',
    component: function component() {
      return import('@/pages/long_time_order/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加长租订单'
    }
  }, {
    path: '/long_time_order/detail',
    name: 'long_time_order_detail',
    component: function component() {
      return import('@/pages/long_time_order/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '长租订单详情'
    }
  }, {
    path: '/long_time_order/add_detail',
    name: 'long_time_order_add_detail',
    component: function component() {
      return import('@/pages/long_time_order/add_detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加预订场地'
    }
  }, {
    path: '/long_time_order/edit',
    name: 'long_time_order_edit',
    component: function component() {
      return import('@/pages/long_time_order/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑长租订单'
    }
  }, //储物柜订单
  {
    path: '/locker_order',
    name: 'locker_order',
    component: function component() {
      return import('@/pages/locker_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '储物柜订单管理'
    }
  }, {
    path: '/locker_order/add',
    name: 'locker_order_add',
    component: function component() {
      return import('@/pages/locker_order/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加储物柜订单'
    }
  }, {
    path: '/locker_order/edit',
    name: 'locker_order_edit',
    component: function component() {
      return import('@/pages/locker_order/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑储物柜订单'
    }
  }, //核销管理
  {
    path: '/writeOff',
    name: 'writeOff',
    component: function component() {
      return import('@/pages/writeOff/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '核销管理'
    }
  }, {
    path: '/writeOff_list',
    name: 'writeOff_list',
    component: function component() {
      return import('@/pages/writeOff/list');
    },
    meta: {
      auth: true,
      cache: true,
      title: '核销列表'
    }
  }, //核销管理
  {
    path: '/field_writeoff',
    name: 'field_writeoff',
    component: function component() {
      return import('@/pages/field_writeoff/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '核销管理'
    }
  }, {
    path: '/field_writeoff/list',
    name: 'field_writeoff_list',
    component: function component() {
      return import('@/pages/field_writeoff/list');
    },
    meta: {
      auth: true,
      title: '核销列表'
    }
  }, //角色
  {
    path: '/role',
    name: 'role',
    component: function component() {
      return import('@/pages/role/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '角色列表'
    }
  }, {
    path: '/role_form',
    name: 'role_form',
    component: function component() {
      return import('@/pages/role/form');
    },
    meta: {
      auth: true,
      cache: true,
      title: '角色编辑'
    }
  }, //管理员
  {
    path: '/admin',
    name: 'admin',
    component: function component() {
      return import('@/pages/admin/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '管理员列表'
    }
  }, {
    path: '/admin_form',
    name: 'admin_form',
    component: function component() {
      return import('@/pages/admin/form');
    },
    meta: {
      auth: true,
      cache: true,
      title: '管理员编辑'
    }
  }, //支付配置
  {
    path: '/pay_config',
    name: 'pay_config',
    component: function component() {
      return import('@/pages/pay_config/index');
    },
    meta: {
      auth: true,
      cache: false,
      title: '支付配置'
    }
  }, //日志
  {
    path: '/log',
    name: 'log_index',
    component: function component() {
      return import('@/pages/log/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '操作日志 '
    }
  }, //二维码配置
  {
    path: '/tdCode',
    name: 'tdCode',
    component: function component() {
      return import('@/pages/configSetting/tdCode');
    },
    meta: {
      auth: true,
      cache: false,
      title: '二维码设置 '
    }
  }, //微信支付配置
  {
    path: '/wx_pay_config',
    name: 'wx_pay_config',
    component: function component() {
      return import('@/pages/configSetting/wx_pay');
    },
    meta: {
      auth: true,
      title: '公众号支付设置'
    }
  }, //非税设置
  {
    path: '/non_tax_config',
    name: 'non_tax_config',
    component: function component() {
      return import('@/pages/configSetting/non_tax');
    },
    meta: {
      auth: true,
      cache: false,
      title: '非税设置'
    }
  }, //轮播图
  {
    path: '/swiper',
    name: 'swiper',
    component: function component() {
      return import('@/pages/swiper/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '轮播图设置 '
    }
  }, {
    path: '/swiper/add',
    name: 'swiper_add',
    component: function component() {
      return import('@/pages/swiper/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加轮播图 '
    }
  }, {
    path: '/swiper/edit',
    name: 'swiper_edit',
    component: function component() {
      return import('@/pages/swiper/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑轮播图 '
    }
  }, {
    path: '/notice',
    name: 'notice',
    component: function component() {
      return import('@/pages/notice/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '公告设置 '
    }
  }, {
    path: '/notice/add',
    name: 'notice_add',
    component: function component() {
      return import('@/pages/notice/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加公告'
    }
  }, {
    path: '/notice/edit',
    name: 'notice_edit',
    component: function component() {
      return import('@/pages/notice/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑公告'
    }
  }, {
    path: '/agreement',
    name: 'agreement',
    component: function component() {
      return import('@/pages/agreement/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '用户协议设置'
    }
  }, {
    path: '/agreement/add',
    name: 'agreement_add',
    component: function component() {
      return import('@/pages/agreement/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加用户协议'
    }
  }, {
    path: '/agreement/edit',
    name: 'agreement_edit',
    component: function component() {
      return import('@/pages/agreement/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑用户协议'
    }
  }, //对账管理
  {
    path: '/accounts',
    name: 'accounts',
    component: function component() {
      return import('@/pages/accounts/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '对账管理'
    }
  }, {
    path: '/field_accounts',
    name: 'field_accounts',
    component: function component() {
      return import('@/pages/field_accounts/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '对账管理'
    }
  }, //订单批量修改
  {
    path: '/batchEdit',
    name: 'batchEdit',
    component: function component() {
      return import('@/pages/orders/batchEdit');
    },
    meta: {
      auth: true,
      title: '批量修改'
    }
  }, //统计报表
  {
    path: '/statistics',
    name: 'statistics',
    component: function component() {
      return import('@/pages/statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '统计报表'
    }
  }, {
    path: '/field_statistics',
    name: 'field_statistics',
    component: function component() {
      return import('@/pages/field_statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '统计报表'
    }
  }, {
    path: '/package_ticket_statistics',
    name: 'package_ticket_statistics',
    component: function component() {
      return import('@/pages/package_ticket_statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '套票统计'
    }
  }, {
    path: '/locker_statistics',
    name: 'locker_statistics',
    component: function component() {
      return import('@/pages/locker_statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '储物柜统计'
    }
  }, {
    path: '/long_time_statistics',
    name: 'long_time_statistics',
    component: function component() {
      return import('@/pages/long_time_statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '长租订单统计'
    }
  }, //日报表
  {
    path: '/daily',
    name: 'daily',
    component: function component() {
      return import('@/pages/dailySheet/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '日报表'
    }
  }, {
    path: '/field_daily',
    name: 'field_daily',
    component: function component() {
      return import('@/pages/field_daily/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '日报表'
    }
  }, {
    path: '/payment_record',
    name: 'payment_record',
    component: function component() {
      return import('@/pages/payment_record/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '资金流水查询'
    }
  }, //砸机设置
  {
    path: '/machine_config',
    name: 'machine_config',
    component: function component() {
      return import('@/pages/machine_config/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '闸机设置'
    }
  }, //砸机编辑
  {
    path: '/machine_config/save',
    name: 'machine_config_save',
    component: function component() {
      return import('@/pages/machine_config/save');
    },
    meta: {
      auth: true,
      cache: true,
      title: '闸机编辑'
    }
  }, //班次管理
  {
    path: '/sign_frequency_index',
    name: 'sign_frequency',
    component: function component() {
      return import('@/pages/sign_frequency/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '班次管理'
    }
  }, {
    path: '/sign_frequency_add',
    name: 'sign_frequency_add',
    component: function component() {
      return import('@/pages/sign_frequency/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加班次'
    }
  }, {
    path: '/sign_frequency_edit',
    name: 'sign_frequency_edit',
    component: function component() {
      return import('@/pages/sign_frequency/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑班次'
    }
  }, //排班设置
  {
    path: '/sign_scheduling_save',
    name: 'sign_scheduling_save',
    component: function component() {
      return import('@/pages/sign_scheduling/save');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班设置'
    }
  }, //排班设置
  {
    path: '/sign_scheduling_add',
    name: 'sign_scheduling_add',
    component: function component() {
      return import('@/pages/sign_scheduling/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '设置排班'
    }
  }, //排班编辑
  {
    path: '/sign_scheduling_change',
    name: 'sign_scheduling_change',
    component: function component() {
      return import('@/pages/sign_scheduling/change');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班编辑'
    }
  }, //排班记录表
  {
    path: '/sign_scheduling_index',
    name: 'sign_scheduling_index',
    component: function component() {
      return import('@/pages/sign_scheduling/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班管理'
    }
  }, //排班记录表
  {
    path: '/sign_scheduling_edit',
    name: 'sign_scheduling_edit',
    component: function component() {
      return import('@/pages/sign_scheduling/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班记录表编辑'
    }
  }, //签到记录表
  {
    path: '/sign_record_index',
    name: 'sign_record_index',
    component: function component() {
      return import('@/pages/sign_record/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤管理'
    }
  }, //签到记录表
  {
    path: '/sign_record_edit',
    name: 'sign_record_edit',
    component: function component() {
      return import('@/pages/sign_record/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤编辑'
    }
  }, //考勤记录
  {
    path: '/sign_scheduling_show',
    name: 'sign_scheduling',
    component: function component() {
      return import('@/pages/sign_scheduling/show');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤记录'
    }
  }, //考勤汇总
  {
    path: '/sign_excel_gather',
    name: 'sign_excel_gather',
    component: function component() {
      return import('@/pages/sign_excel/gather');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤汇总'
    }
  }, {
    path: '/sign_scheduling_detail',
    name: 'sign_scheduling_detail',
    component: function component() {
      return import('@/pages/sign_scheduling/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '个人详情'
    }
  }, //非税退订订单
  {
    path: '/refund_order',
    name: 'refund_order',
    component: function component() {
      return import('@/pages/refund_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '退订订单'
    }
  }, //人脸
  {
    path: '/face/users',
    name: 'face_users',
    component: function component() {
      return import('@/pages/face/users');
    },
    meta: {
      auth: true,
      cache: true,
      title: '用户管理'
    }
  }, {
    path: '/face/staff',
    name: 'face_staff',
    component: function component() {
      return import('@/pages/face/staff');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工管理'
    }
  }, {
    path: '/face/add',
    name: 'face_add',
    component: function component() {
      return import('@/pages/face/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '人脸添加'
    }
  }, {
    path: '/face/edit',
    name: 'face_edit',
    component: function component() {
      return import('@/pages/face/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '人脸编辑'
    }
  }, {
    path: '/app_config',
    name: 'app_config',
    component: function component() {
      return import('@/pages/app_config/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: 'app管理'
    }
  }, {
    path: '/locker_agreement',
    name: 'locker_agreement',
    component: function component() {
      return import('@/pages/locker_agreement/index');
    },
    meta: {
      auth: true,
      title: '储物柜协议'
    }
  }, {
    path: '/locker_agreement/form',
    name: 'locker_agreement_form',
    component: function component() {
      return import('@/pages/locker_agreement/form');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑储物柜协议'
    }
  }, // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);