import _objectSpread from "D:/wwwroot/local81.lzj/bespeak/admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { get } from 'lodash';
export default {
  data: function data() {
    return {
      uploading: false
    };
  },
  computed: _objectSpread({}, mapState('d2admin/log', ['log'])),
  methods: {
    get: get,
    handleShowMore: function handleShowMore(log) {
      // 打印一条日志的所有信息到控制台
      this.$notify({
        type: 'info',
        title: '日志详情',
        message: '完整的日志内容已经打印到控制台'
      });
      this.$log.capsule('D2Admin', 'handleShowMore', 'primary');
      console.group(log.message);
      console.log('time: ', log.time);
      console.log('type: ', log.type);
      console.log(log.meta);
      console.groupEnd();
    },
    // 日志上传
    handleUpload: function handleUpload() {
      var _this = this;

      this.uploading = true;
      this.$notify({
        type: 'info',
        title: '日志上传',
        message: "\u5F00\u59CB\u4E0A\u4F20".concat(this.log.length, "\u6761\u65E5\u5FD7")
      });
      setTimeout(function () {
        _this.uploading = false;

        _this.$notify({
          type: 'success',
          title: '日志上传',
          message: '上传成功'
        });
      }, 3000);
    }
  }
};